<template>
  <div class="container mx-auto">
    <h1
    :id="categoryData.cat_alias"
      class="cat-name font-medium text-xl font-ceraroundMedium text-easyText mb-6 md:text-text45px md:mt-20"
    >
{{ categoryData.cat_name }}
    </h1>
    <CatalogItem :itemData="categoryData.data" />

  </div>
</template>

<script>
import CatalogItem from "@/components/CatalogItem.vue";
export default {
  name: "CatalogList",
   props: {
    categoryData: Object,
  },
  components: {
    CatalogItem,
  },
};
</script>
