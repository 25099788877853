<template>
  <div class="fixed
          inset-0
          flex
          xl:items-center
          justify-center
          z-1000
          bg-gray-700 bg-opacity-50">
          <div v-scroll-lock="isOpen" ref="el" class="xl:w-3/5 w-full px-2 xl:p-6 xl:mx-4 bg-white shadow-xl xl:rounded-round50px relative overflow-y-scroll overflow-x-hidden xl:overflow-visible">
            <div @click="close" class="absolute -right-20 top-0 cursor-pointer"><img class="w-full" src="../assets/icons/x.svg" /></div>
  <div class="font-bold text-2xl block xl:flex xl:pr-10 xl:py-12 min-h-screen xl:min-h-fit">
      <div class="xl:hidden flex justify-between items-center p-2">
        <div><img class="w-full" src="../assets/icons/heart-mobile.svg" /></div>
        <div @click="close"><img class="w-full" src="../assets/icons/chevron-down.svg" /></div>
      </div>
      <div class="xl:w-6/12 flex items-center mt-2 xl:mt-0 justify-center">
      <img class="item" :src="data.itemImage2.webp" />
      </div>
      <div class="xl:w-6/12 ml-3">
           <h2 class="text-text26px font-ceraroundRegular text-easyText pt-2 pb-1 md:pt-0 md:pb-0">
            {{ data.longtitle }}
          </h2>
          <p class="font-ceraroundThin text-xs text-gray-400 mb-3 md:mt-2 md:text-lg">{{ data.params.weight }}</p>
                 <div class="flex justify-between p-6 text-sm font-ceraroundRegular text-easyText text-center" v-if="data.params.calorificValue !== '-'">
              <div><p>{{ data.params.calorificValue }}</p><span class="text-grayCalories font-ceraroundThin text-xs">ккал</span></div> 
               <div><p>{{ data.params.carbons }}</p><span class="text-grayCalories font-ceraroundThin text-xs">углеводы</span></div>
              <div><p>{{ data.params.proteins }}</p><span class="text-grayCalories font-ceraroundThin text-xs">белки</span></div>
              <div><p>{{ data.params.fats }}</p><span class="text-grayCalories font-ceraroundThin text-xs">жиры</span></div>
            </div>
            <p class="xl:text-lg font-ceraroundThin text-sm text-gray-400 mb-4 leading-5 xl:leading-7">{{ data.introtext }}</p>


<ToppingsWidget :toppings="data.params.availableToppings" v-if="data.params.availableToppings !== null" /> 
        
     <div class="xl:flex flex-nowrap justify-between w-full mt-12 hidden">
      <div v-if="!itemAlreadyInCart">
      <button @click="addToCart(data)" class="bg-easyGreen text-white font-ceraroundLight rounded-lg py-3 px-10 text-lg">В корзину</button>
      </div>


      <div class="flex flex-wrap" v-if="itemAlreadyInCart">
      <button class="bg-yellow-300 py-3 pl-3.5 rounded-l-lg" @click="changeQuantity(data, 'decrease')"><img src="../assets/icons/trash.svg" /></button><button class="bg-yellow-300 text-easyText font-ceraroundLight py-3 px-10 text-lg">{{ data.quantity}} шт</button><button @click="changeQuantity(data)" class="bg-yellow-300 py-3 pr-3.5 text-xl rounded-r-lg font-ceraroundLight"><img src="../assets/icons/plus.svg" /></button>
      </div>

            <div class="text-easyGreen font-ceraroundLight text-text3xl5 flex flex-wrap justify-center content-center"><p>{{ data.price }} ₽</p>
            </div>
          </div>

      <div class="xl:hidden">
      <div class="flex-nowrap justify-between w-full mt-12 flex pr-4" v-if="!itemAlreadyInCart">
      <button @click="addToCart(data)" class="bg-easyGreen text-white font-ceraroundLight rounded-lg py-3 px-10 text-lg w-full">В корзину за {{ data.price }} ₽</button>
      </div>

      <div class="flex flex-wrap mt-12 pr-4" v-if="itemAlreadyInCart">
      <button class="bg-yellow-300 py-3 px-3.5 rounded-l-lg" @click="changeQuantity(data, 'decrease')"><img src="../assets/icons/trash.svg" /></button><button class="bg-yellow-300 text-easyText font-ceraroundLight py-3 px-10 text-lg grow">{{ data.quantity}} шт</button><button @click="changeQuantity(data)" class="bg-yellow-300 py-3 px-3.5 text-xl rounded-r-lg font-ceraroundLight"><img src="../assets/icons/plus.svg" /></button>
      </div>
      </div>
      
          </div>
    </div>
            <BottomMenu class="mt-4 xl:hidden sticky bottom-0 bg-white" />
  </div>
  </div>
</template>

<script setup>
import ToppingsWidget from '@/components/ToppingsWidget.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import { ref, computed } from 'vue'
import {useStore} from "vuex";
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  data: Object,
  isOpen: Boolean,
});

const emit = defineEmits(['close']);

const el = ref()

function close () {
 emit('close');
}

onClickOutside(el, close)

const store = useStore();
    let cart = computed(function () {
            return store.state.cart
        });
  let itemAlreadyInCart = computed(function() {
            let inCart = false;

            cart.value.forEach(item => {
                if(item.id == props.data.id){
                    inCart = true;
                }
            });

            return inCart;
        });

        function addToCart(data){
                store.commit("addCartItem", data);
        };

           function changeQuantity(data, action = 'add'){

            if(action == 'add'){
                data.quantity = data.quantity + 1;
                store.commit("updateCartItem", data);
            }else{

                if(props.data.quantity > 1){
                    data.quantity = data.quantity - 1;
                    store.commit("updateCartItem", data);
                }else{
                    store.commit("removeCartItem", data)
                }
            }
          };

        //     function removeItem(){
        //     store.commit("removeCartItem", props.data)
        // }


</script>

<style>

</style>