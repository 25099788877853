<template>
    <div class="px-4 xl:px-0">
    <SliderMain />
    </div>
      <div class="container mx-auto px-4 py-0 md:w-screen">
    <StickyMenu class="md:hidden" :menuCat="menu" />
    <div class="tilesMenu md:hidden">
    <div id="sec-3" class="flex mb-4 mt-3">
      <div class="w-2/3 bg-cat-1 h-28 bg-cover rounded mr-2"></div>
      <div class="w-1/3 bg-cat-2 first-letter:h-28 bg-cover rounded"></div>
    </div>
    <div class="flex mb-4">
      <div class="w-1/3 bg-cat-3 h-28 bg-cover rounded mr-2"></div>
      <div class="w-2/3 bg-cat-4 h-28 bg-cover rounded"></div>
    </div>
    <div class="flex mb-4">
      <div class="w-2/3 bg-cat-5 h-28 bg-cover rounded mr-2"></div>
      <div class="w-1/3 bg-cat-6 h-28 bg-cover rounded"></div>
    </div>
    <div class="flex mb-10">
      <div class="w-1/3 bg-cat-7 h-28 bg-cover rounded mr-2"></div>
      <div class="w-2/3 bg-cat-8 h-28 bg-cover rounded"></div>
    </div>
    </div>
    <div v-scroll-spy class="spy_box">
    <div v-for="full in products" :key="full.id">
    <CatalogList :categoryData="full" />
    </div>
    </div>
    <BottomMenu class="md:hidden" />
    <div class="hidden md:block pb-32 pt-10">
      <h1 class="text-easyGreen font-ceraroundMedium text-text45px leading-tight my-10">Easy sushi - это единственное место в Петербурге, где<br>абсолютно все роллы<br>
и WOK'и стоят фиксированные 169 рублей!</h1>

<div class="flex text-text26px gap-20 font-ceraroundRegular text-easyText">
  <div class="w-1/2">
Easy sushi — это единственное место в Санкт-Петербурге, где абсолютно все роллы и WOK стоят фиксированные 169 рублей!
<br><br>
Вы можете знать и помнить нас как Shop Fix Sushi<br>и Sushi Fix (Суши Фикс). В рамках ребрендинга мы изменили название, но наша концепция осталась неизменной — японский street food по доступной цене!
  </div>
    <div class="w-1/2">
    В меню
    <ul class="list-disc pl-7">
<li>роллы, онигири, WOK, закуски, горячее, десерты</li>
<li>прохладительные коктейли, морсы,<br>зерновой кофе от 79 рублей</li>
</ul>
Приходите и убедитесь: вкусно — не значит<br>дорого!
  </div>
</div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import CatalogList from '@/components/CatalogList.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import SliderMain from '@/components/SliderMain.vue';
import StickyMenu from '@/components/StickyMenu.vue';
const store = useStore();
const products = computed(() => {
  return store.state.products;
});
const menu = computed(() => {
  return store.state.menu;
});
onMounted(() => {
  store.dispatch("fetchData");
});
</script>

