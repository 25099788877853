<template>
   <div class="swiper-box sticky top-0 bg-white/[.55] py-3">
    <div class="icon_totop_and_menu_box flex"
           v-scroll-spy="{allowNoActive: true, offset: 70}"
        v-scroll-spy-active="{selector: '.item_link', class: 'spy_active'}"
    >

      <a href="#totop" v-smooth-scroll><div class="icon rounded mr-1">
        <img src="../assets/cat_menu/up.png" />
      </div></a>



      <swiper 
      :scrollbar="{
      hide: true,
      draggable: true,
    }" :spaceBetween="5" :grabCursor="true" :modules="modules" :slideToClickedSlide="true" :slidesPerView="'auto'"
        class="mySwiper-menu text-center font-ceraroundMedium text-easyText pb-1"
        >

        <swiper-slide class="item_link" v-for="menuCategory in menuCat" :key="menuCategory.id"><a :href="'#' + menuCategory.alias" v-smooth-scroll>{{ menuCategory.longtitle}}</a></swiper-slide>

      </swiper>

      </div>
    </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';

export default {
name: 'StickyMenu',
props: {
    menuCat: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
modules: [Pagination, Scrollbar],
    };
  },
}

</script>

<style>

.mySwiper-menu .swiper-slide {
  width: fit-content !important;
  background: #d9d9d9;
  padding: 3px 15px;
  border-radius: 5px;
  color: #424242;
  font-weight: 500;
}

.swiper-box .icon {
  background: #41cc89;
  min-width: 30px;
  height: 30px;
}

.spy_active {
  background: #41cc89 !important;
  color:white !important;
}
.swiper-scrollbar-drag {
    background: #41cc89 !important;
    height: 3px;
}
.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
    left: var(--swiper-scrollbar-sides-offset, 0%);
    bottom: var(--swiper-scrollbar-bottom, 1px);
}
.swiper-scrollbar {
    background: none;
}
</style>