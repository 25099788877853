<template>
  <div class="fixed
          inset-0
          block
          xl:items-center
          justify-center
          z-1000
          bg-gray-700 bg-opacity-50 overflow-y-scroll">
          <div v-scroll-lock="isOpen" ref="el" class="lg:w-910px w-full xl:py-12 xl:mt-8 bg-white shadow-xl xl:rounded-round50px relative overflow-y-scroll overflow-x-hidden xl:overflow-visible mx-auto">
            <div @click="close" class="absolute -right-20 top-0 cursor-pointer"><img class="w-full" src="../assets/icons/x.svg" /></div>

  <div class="flex flex-row flex-wrap xl:px-32 pt-16 mb-10">
    <div class="flex-col w-1/2">
           <h1 class="text-4xl font-ceraroundMedium mb-6 text-gray-900">Корзина</h1>
           <p v-if="totalPrice == 0" class="text-lg mb-14 text-easyText font-ceraroundRegular">Ой! Корзина-то пуста... Добавьте товар, прежде чем оформить заказ.</p>
                <button v-if="totalPrice == 0" @click="close" class="bg-easyGreen font-ceraroundRegular text-white rounded-lg py-3 px-10 text-lg w-full">Начать покупки</button>
        
    </div>
    <div class="flex-col w-1/2 xl:flex hidden">
        <div class="flex text-lg">
            <div class="flex flex-col w-60 flex-wrap justify-start items-center">
            <div class="w-50px h-50px bg-easyGreen rounded-full flex justify-center items-center text-white line--right font-ceraroundMedium">1</div>
            <p class="flex justify-center items-center content-center text-center font-ceraroundRegular leading-5 text-easyText mt-2">Корзина</p>
            </div>
            <div class="flex flex-col w-60 flex-wrap justify-start items-center">  
            <div class="w-50px h-50px  rounded-full flex justify-center items-center text-easyGreen line--right2 font-ceraroundMedium border-solid border-2 border-easyGreen">2</div>
            <p class="flex justify-center items-center content-center text-center font-ceraroundRegular leading-5 text-easyText mt-2">Оформление<br>заказа</p>
            </div>
            <div class="flex flex-col w-60 flex-wrap justify-start items-center">
                  <div class="w-50px h-50px rounded-full flex justify-center items-center text-easyGreen font-ceraroundMedium border-solid border-2 border-easyGreen">3</div>
                  <p class="flex justify-center items-center content-center text-center font-ceraroundRegular leading-5 text-easyText mt-2">Заказ<br>принят</p>
                  </div>
        </div>
    </div>
</div>
<div v-if="totalPrice !== 0" class="xl:px-32">
  <h1 class="text-grayc2 text-text22px font-ceraroundLight">Состав заказа</h1>
           <ul>
          <TransitionGroup name="list" tag="ul">
            <li class="mb-4" v-for="item in cart" :key="item.id">
              <div class="flex bg-easyBack rounded-xl">
                <div class="xl:w-220px flex items-center xl:mt-0 justify-center mx-2">
      <img class="item" :src="item.itemImage2.webp" />
      </div>
      <div class="text-2xl text-easyText flex flex-col justify-center">
                {{item.longtitle}}
                 <p class="font-ceraroundThin text-xs text-gray-400 mb-3 md:mt-2 md:text-lg">{{ item.params.weight }}</p>
                </div>
                <div @click="removeItem(item)" class="text-easyText flex flex-col justify-center items-end grow text-right pr-4"><img class="w-10 cursor-pointer" src="../assets/icons/close.svg" /></div>
                </div>
              <div class="flex justify-between bg-easyBack mt-1 py-2 rounded-xl">
                <span class="text-easyText text-text28px font-ceraroundMedium ml-10">{{item.price * item.quantity}} ₽</span>
                        
                <div>
                <button @click="changeQuantity(item, 'decrease')" class="text-grayc2 text-text28px font-ceraroundRegular mr-4">-</button>
                <span class="text-easyText text-text28px font-ceraroundMedium">{{item.quantity}}</span><button @click="changeQuantity(item)" class="text-grayc2 text-text28px font-ceraroundregular ml-4 mr-6">+</button>
                </div>
                        </div>
            </li>
            </TransitionGroup>
        </ul>

   <div class="mt-10 flex justify-between font-ceraroundMedium text-text26px text-easyText">
                <div>Итого к оплате</div>
                <div>{{totalPrice}} ₽</div>
            </div>
</div>

 <div class="xl:px-32 bg-easyBack xl:py-8 xl:mt-4" v-if="totalPrice !== 0">
        <h1 class="text-easyText font-ceraroundMedium text-text37px mb-4">Рекомендуем</h1>
         <swiper
    :slides-per-view="3"
    :freeMode="true"
    :space-between="20"
    :mousewheel="true"
    :grabCursor="true"
    :setWrapperSize="true"
  >
    <swiper-slide class="h-auto bg-white p-3 rounded-md" v-for="recProd in recommendProd" :key="recProd.id">
      <div class="h-full flex flex-col justify-between">
         <div>
        <img class="item cursor-grab" :src="recProd.itemImage2.webp" />
        <div class="text-3xl text-easyText font-ceraroundRegular mb-2">{{ recProd.price }} ₽
        </div>
        <div class="text-gray7575 font-ceraroundRegular text-text26px leading-8">{{ recProd.longtitle }}</div>
  </div>
      
         <div class="xl:flex flex-nowrap justify-between w-full mt-12 hidden">
      <div v-if="!inCartMethod(recProd)">
      <button @click="addToCart(recProd)" class="bg-easyBack text-gray-600 font-ceraroundRegular rounded-lg py-3 px-10 text-lg w-full">В корзину</button>
      </div>


      <div class="flex" v-if="inCartMethod(recProd)">
      <button class="bg-yellow-300 py-3 pl-3.5 rounded-l-lg" @click="changeQuantity(recProd, 'decrease')"><img src="../assets/icons/trash.svg" /></button><button class="bg-yellow-300 text-easyText font-ceraroundLight py-3 px-7 text-lg">{{ inCartQuantity(recProd) }} шт</button><button @click="changeQuantity(recProd)" class="bg-yellow-300 py-3 pr-3.5 text-xl rounded-r-lg font-ceraroundLight"><img src="../assets/icons/plus.svg" /></button>
      </div>

         
          </div>

      <div class="xl:hidden">
      <div class="flex-nowrap justify-between w-full mt-12 flex pr-4" v-if="!inCartMethod(recProd)">
      <button @click="addToCart(recProd)" class="bg-easyGreen text-white font-ceraroundLight rounded-lg py-3 px-10 text-lg w-full">В корзину за {{ recProd.price }} ₽</button>
      </div>

      <div class="flex flex-wrap mt-12 pr-4" v-if="inCartMethod(recProd)">
      <button class="bg-yellow-300 py-3 px-3.5 rounded-l-lg" @click="changeQuantity(recProd, 'decrease')"><img src="../assets/icons/trash.svg" /></button><button class="bg-yellow-300 text-easyText font-ceraroundLight py-3 px-10 text-lg grow">{{ inCartQuantity(recProd) }} шт</button><button @click="changeQuantity(recProd)" class="bg-yellow-300 py-3 px-3.5 text-xl rounded-r-lg font-ceraroundLight"><img src="../assets/icons/plus.svg" /></button>
      </div>
      </div>
      </div>
    </swiper-slide>
  </swiper>
</div>


  </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import {useStore} from "vuex";
import { onClickOutside } from '@vueuse/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
 import 'swiper/css/grid';

const props = defineProps({
  isOpen: Boolean,
});

const emit = defineEmits(['close']);
const el = ref()

function close () {
 emit('close');
}

onClickOutside(el, close);

 const store = useStore();

        let cart = computed(function () {
            return store.state.cart;
        });

        let recommendProd = computed(function () {
            return store.state.recommendProd;
        });


        let totalPrice = computed(function(){
            return cart.value.reduce((total, next) => {
                return total + (next.quantity * next.price)
            }, 0)
        });

          function inCartQuantity(item) {
              cart.value.forEach((i) => {
                if(i.id == item.id){
                    item.quantity = i.quantity;
                }
            });
            return item.quantity;
        };

        
            function removeItem(item){
            store.commit("removeCartItem", item)
        };

            function changeQuantity(item, action = 'add'){

            if(action == 'add'){
                item.quantity = item.quantity + 1;

                store.commit("updateCartItem", item);
            }else{
                if(item.quantity > 1){
                    item.quantity = item.quantity - 1;
                    store.commit("updateCartItem", item);
                }else{
                    store.commit("removeCartItem", item)
                }
            }
        };

    function inCartMethod(item) {
        let inCart = false;
          cart.value.forEach((i) => {
                if(i.id == item.id){
                    inCart = true;
                }
            });
            return inCart;
        };

          function addToCart(data){
                store.commit("addCartItem", data);
        };



</script>

<style>
.line--right, .line--right2 {
  position: relative;
}
.line--right:after {
  content: "";
  display: block;
  width: 117%;
  height: 1px;
  border-bottom: 2px dotted #41CC89;
  position: absolute;
  right: -117%;
  top: 50%;
}
.line--right2:after {
  content: "";
  display: block;
  width: 132%;
  height: 1px;
  border-bottom: 2px dotted #41CC89;
  position: absolute;
  right: -132%;
  top: 50%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

</style>