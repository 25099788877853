<template>
  <div class="container mx-auto px-4 md:my-3 pt-1 my-2" id="totop">
    <header class="header xl:block">
      <div class="header__title flex flex-nowrap items-end justify-between md:mb-4">
        <div class="logo_box flex flex-nowrap items-end">
        <router-link to="/"
          ><img
            src="../assets/logo.png"
            class="md:w-48 w-28 cursor-pointer"
            alt="..."
          />
        </router-link>
       <h1 class="hidden xl:block text-easyGreen text-2xl font-ceraroundMedium mx-5 mb-1">Японский street food по фиксированной цене</h1>
       </div>
       <div class="icons_box text-gray-900 xl:flex right-0 relative hidden mb-1 cursor-pointer">
          <router-link to="/sales"><img class="w-3/12 h-6 mx-10" src="../assets/bottom_menu/percent.svg" /></router-link>
        <img class="w-3/12 h-6 mx-10" src="../assets/bottom_menu/person.svg" />
        <img @click="openCart()" class="w-3/12 h-6 mx-10" src="../assets/bottom_menu/bag.svg" />
       </div>
      </div>
      <button
        v-show="buttonShow"
        class="menu-button"
        :class="{ open: menuOpen }"
        @click="menuOpen = !menuOpen"
      >
        <span class="menu-button__bar menu-button__bar--top"></span>
        <span class="menu-button__bar menu-button__bar--center"></span>
        <span class="menu-button__bar menu-button__bar--bottom"></span>
      </button>
      <transition name="fade">
        <nav
          v-show="menuOpen"
          class="nav font-ceraroundMedium"
          :class="{ 'sp-menu': buttonShow }"
        >
           <ul class="flex nav__list text-gray-100 md:text-gray-900 md:py-3 text-text25px">

          <!-- <ul class="nav__list text-gray-100 md:text-gray-900 md:py-3 text-text25px"> -->
            <li v-for="menuItem in $store.state.menu" :key="menuItem.id" class="nav__item mr-4">
              <a class="nav__item first:mr-4 first:mx-0" :href="'#' + menuItem.alias" v-smooth-scroll
                >{{ menuItem.longtitle }}
              </a>
            </li>
            <!-- <li class="nav__item mx-4 xl:mx-0 xl:mr-4 ">
              <router-link class="nav__link" to="/about">Роллы</router-link>
            </li>
                <li class="nav__item mx-4">
              <router-link class="nav__link" to="/about">Запеченные роллы</router-link>
            </li>
                <li class="nav__item mx-4">
              <router-link class="nav__link" to="/about">Стартеры</router-link>
            </li>
              <li class="nav__item mx-4">
              <router-link class="nav__link" to="/about">Горячие блюда</router-link>
            </li>
              <li class="nav__item mx-4">
              <router-link class="nav__link" to="/about">Топпинги</router-link>
            </li>
              <li class="nav__item mx-4">
              <router-link class="nav__link" to="/about">Десерты</router-link>
            </li>
              <li class="nav__item ml-4">
              <router-link class="nav__link" to="/about">Напитки</router-link>
            </li> -->
          </ul>
        </nav>
      </transition>
    </header>
  </div>
   <transition name="fade">
   <CartWidget v-if="openCartState" @close="openCartState = false" :isOpen="openCartState" />
</transition>
</template>

<script>
import CartWidget from '@/components/CartWidget.vue';
export default {
  data() {
    return {
      windowWidth: "",
      menuOpen: "",
      buttonShow: "",
      openCartState: false,
    }
  },
   components: {
    CartWidget,
  },
    created() {
    window.addEventListener("resize", this.getWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  mounted: function () {
    this.getWindowWidth();
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
      this.menuOpen = this.windowWidth >= 1280;
      this.buttonShow = this.windowWidth < 1280;
    },
    openCart() {
      this.openCartState = true;
    },
      isMobile() {
    if (screen.width <= 760) {
      return true;
    } else {
      return false;
    }
  }, 
  },
};
</script>

<style lang="scss">
.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  background: white;
  color: #fefefc;
  &__title {
    font-size: 1.8rem;
  }
}

.menu-button {
  appearance: none;
  position: relative;
  top: calc((3.8rem - 40px) / 2);
  right: 0px;
  display: inline-block;
  width: 36px;
  height: 44px;
  background: none;
  border: none;
  z-index: 1001;
  cursor: pointer;
  &__bar {
    position: absolute;
    top: 0;
    left: 10%;
    display: inline-block;
    width: 80%;
    height: 3px;
    border-radius: 4px;
    background: #41cc89;
    transition: all 0.4s;
    &--top {
      top: 6px;
    }
    &--center {
      top: 15px;
    }
    &--bottom {
      top: 24px;
    }
  }
  &.open & {
    position: fixed;

    &__bar {
      background: white;
      &--top {
        top: 20px;
        transform: rotate(-45deg);
      }
      &--center {
        left: 50%;
        opacity: 0;
        transform: rotate(135deg);
      }
      &--bottom {
        top: 20px;
        transform: rotate(45deg);
      }
    }
  }
}

.nav {
  &__list {
    display: flex;
    gap: 1rem;
    list-style: none;
  }
  &.sp-menu {
    position: fixed;
    top: 0;
    right: 0;
    padding: 50px;
    width: calc(100% - 100px);
    height: calc(100vh);
    background: #3c3946;
    z-index: 1000;
  }
  &.sp-menu & {
    &__list {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 1rem;
      }
    }
  }
  &__link {
    position: relative;
    color: inherit;
    font-size: 1.4rem;
    text-decoration: none;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.4s ease, transform 0.4s ease;
}
}
</style>
