<template>
  <div class="bg-easyGreen text-white pb-6 md:full">
    <div class="flex flex-col container mx-auto py-4 md:py-10">
      <div class="md:flex md:justify-between mx-4 mb-4">
      <ul class="font-ceraroundRegular text-xl md:flex">
        <li class="my-4 mr-8">Зоны доставки</li>
        <li class="my-4 mr-8">Магазины</li>
        <li class="my-4 mr-8">Вакансии</li>
        <li class="my-4 mr-8">Франшиза</li>
        <li class="my-4 mr-8">О нас</li>
        </ul>
        <ul class="font-ceraroundRegular text-xl md:mt-4 md:pb-4">
        <li class="mb-4">Публичная оферта</li>
        <li class="my-4">Пользовательское соглашение</li>
        <li class="my-4">Политика конфиденциальности</li>
        <li class="my-4">Правила оплаты и возврата</li>
        <li class="mt-4">Реквизиты</li>
      </ul>
      </div>
      <hr />
      <div class="social-block flex flex-col mx-4">
        <p class="font-ceraroundMedium text-xl mt-3">Наши соцсети</p>
        <div class="flex flex-row my-3">
          <div>
            <img class="w-12/12 mr-7 mt-1" src="../assets/icons/tg.svg" />
          </div>
          <div><img class="w-12/12 h-8" src="../assets/icons/tt.svg" /></div>
        </div>
        <div class="flex flex-row my-3">
          <div>
            <img class="w-12/12 h-8" src="../assets/easylogo_white.svg" />
          </div>
          <div class="font-ceraroundMedium text-base ml-3">@2023</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBlock",
};
</script>
