<template>
  <div class="touch-manipulation">
  <div><Navbar /></div>
  <router-view />
   <FooterBlock />
   </div>
</template>

<script>
import Navbar from "./views/Navbar.vue";
import FooterBlock from '@/components/FooterBlock.vue';
export default {
  name: "App",
  components: {
    Navbar,
    FooterBlock,
  },
};
</script>
