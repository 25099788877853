<template>
   <swiper :navigation="true" :spaceBetween="30" :pagination="{
      clickable: true,
    }"
     :breakpoints="{
      '@0.00': {
        slidesPerView: 1,
        spaceBetween: 10,
        navagation: false,
        navigation: {
         disabledClass: 'swiper-button-disabled',
         hiddenClass: 'swiper-button-hidden',
         navigationDisabledClass: 'swiper-navigation-disabled',
        },
      },
      '@0.75': {
        slidesPerView: 1,
        spaceBetween: 20,
        enabled: true,
      },
    }"
    :modules="modules" class="mySwiper-slider rounded text-sm">
      <swiper-slide>
        <a class="block md:hidden" href="#napitki_sfs" v-smooth-scroll><img src="../assets/slide1.png" /></a>
        <a class="hidden md:block" href="#" v-smooth-scroll><img src="../assets/slide_pc.png" /></a>
    </swiper-slide>
      <swiper-slide>
        <a class="block md:hidden" href="#napitki_sfs" v-smooth-scroll><img src="../assets/slide1.png" /></a>
        <a class="hidden md:block" href="#" v-smooth-scroll><img src="../assets/slide_pc.png" /></a>
      </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

export default {
name: 'SliderMain',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
   
    return {
modules: [Pagination, Navigation],
    };
  },
}
</script>

<style>
.mySwiper-slider {
 width: 100%;
 height: 100%;
    padding-bottom: 35px;
}

.mySwiper-slider .swiper-pagination-bullet-active {
  background: #41cc89;
}

.mySwiper-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper-slider .swiper-button-prev {
  width: 47px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  --swiper-navigation-size: 20px;
  background-image: url('/src/assets/icons/left_arrow.png');
  background-repeat: no-repeat;
}

.mySwiper-slider .swiper-button-next {
     width: 47px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  --swiper-navigation-size: 20px;
  background-image: url('/src/assets/icons/right_arrow.png'); 
  background-repeat: no-repeat;
}

.mySwiper-slider .swiper-button-prev::after,
.mySwiper-slider .swiper-button-next::after {
content: none;
}
@media (max-width: 768px) {
.mySwiper-slider .swiper-button-prev, .mySwiper-slider .swiper-button-next {
    display: none;
}
}
@media (min-width: 768px) {
.mySwiper-slider .swiper-pagination  {
    display: none;
}
}
</style>