import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    products: [],
    menu: [],
    cart: [],
    recommendProd: [],
  },
  getters: {
    getProducts: (state) => state.products,
    getMenu: (state) => state.menu,
  },
  mutations: {
    SET_RECOMMEND(state, recommendProd) {
      state.recommendProd = recommendProd;
    },
    SET_CART(state, cart) {
      state.cart = cart;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_MENU(state, menu) {
      state.menu = menu;
    },
    addCartItem(state, item) {
      item.quantity = 1;
      state.cart.push(item);
      localStorage.setItem("cart", JSON.stringify(state.cart));
      console.log(state.cart);
    },
    updateCartItem(state, updatedItem) {
      state.cart = state.cart.map((cartItem) => {
        if (cartItem.id == updatedItem.id) {
          localStorage.setItem("cart", JSON.stringify(state.cart));
          return updatedItem;
        }
        localStorage.setItem("cart", JSON.stringify(state.cart));
        return cartItem;
      });
    },
    removeCartItem(state, item) {
      state.cart = state.cart.filter((cartItem) => {
        return cartItem.id != item.id;
      });
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
  actions: {
    async fetchData({ commit }) {
      try {
        let one = 'https://www.sushieasy.ru/api/shop/menu';
        let two = 'https://www.sushieasy.ru/api/shop/category';
        let recommendProdLink = 'https://www.sushieasy.ru/api/shop/related';
        const requestOne = axios.get(one);
        const requestTwo = axios.post(two, { city: 'Санкт-Петербург', cid: 24404 });
        const requestThree = axios.post(two, { city: 'Санкт-Петербург', cid: 24405 });
        const requestFour = axios.post(two, { city: 'Санкт-Петербург', cid: 43417 });
        const requestFive = axios.post(two, { city: 'Санкт-Петербург', cid: 24407 });
        const requestSix = axios.post(two, { city: 'Санкт-Петербург', cid: 24408 });
        const requestSeven = axios.post(two, { city: 'Санкт-Петербург', cid: 24409 });
        const requestEight = axios.post(two, { city: 'Санкт-Петербург', cid: 24410 });
        const requestNine = axios.get(recommendProdLink, { city: 'Санкт-Петербург', cid: 2 });
        let menuData;
        let productsData;
        let recommendProd;
        await axios
          .all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix, requestSeven, requestEight, requestNine])
          .then(axios.spread((...responses) => {
            menuData = responses[0].data.successData.categories;
            recommendProd = responses[8].data.successData;
            productsData = [{ data: responses[1].data.successData, cat_name: 'Роллы', cat_alias: 'rolly_sfs' }, { data: responses[2].data.successData, cat_name: 'Запеченные роллы', cat_alias: 'rolly_zapechennye_sfs' }, { data: responses[3].data.successData, cat_name: 'Стартеры', cat_alias: 'sendvichi-sfs' }, { data: responses[4].data.successData, cat_name: 'Горячие блюда', cat_alias: 'goryachie_blyuda_sfs' }, { data: responses[5].data.successData, cat_name: 'Десерты', cat_alias: 'deserty_sfs' }, { data: responses[6].data.successData, cat_name: 'Напитки', cat_alias: 'napitki_sfs' }, { data: responses[7].data.successData, cat_name: 'Дополнительно', cat_alias: 'dop_zakaz_sfs' }];
          })
          )
        commit("SET_PRODUCTS", productsData);
        commit("SET_MENU", menuData);
        commit("SET_RECOMMEND", recommendProd);
        console.log(recommendProd);

        if (!localStorage.getItem("cart")) {
          localStorage.setItem("cart", JSON.stringify([]));
        } else {
          commit("SET_CART", JSON.parse(localStorage.getItem("cart")));
        }

      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
  },
  modules: {},
});

