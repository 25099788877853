<template>
  <div class="font-ceraroundRegular text-2xl text-gray-800 mb-6 pt-6">Добавить</div>
  <div class="flex flex-wrap flex-row w-full justify-start font-ceraroundLight">
  <div class="flex flex-col basis-auto w-auto text-sm xl:text-lg px-4 py-2 bg-easyBack mr-2 mb-2 lowercase text-gray-800 rounded-lg cursor-pointer" v-for="topping in toppings" :key="topping.id"><p>{{ topping.pagetitle }} <span class="text-easyGreen ml-1">{{ topping.price }} ₽</span></p></div>
  </div>
</template>

<script>
export default {
  props: {
    toppings: Object,
  },
}
</script>
