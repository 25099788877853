<template>
 <div class="container mx-auto md:p-6 grid grid-cols-1 gap-8 xl:grid-cols-4 md:grid-cols-2">
  <div v-for="item in itemData" :key="item.id" class="col-span-1 flex flex-col bg-white md:p-4">
    <div class="mb-2 font-bold text-2xl flex md:block">
      <div v-on:click="openModal(item)" class="cursor-pointer">
      <img class="item" :src="item.itemImage2.webp" />
      </div>
      <div class="w-3/4 md:w-full">
           <h2 class="text-base font-ceraroundRegular text-easyText md:text-2xl pt-2 pb-1 md:pt-0 md:pb-0">
            {{item.longtitle}}
          </h2>
          <p class="font-ceraroundThin text-xs text-gray-400 mb-3 md:mt-2 md:text-lg">{{ item.params.weight }}</p>
               
          <div @click="addToCart(item)" class="flex flex-wrap h-10 md:hidden" v-if="!inCartMethod(item)">
        <button
            class="bg-easyGreen text-white font-ceraroundThin text-sm py-2 px-3 rounded-lg w-16 mt-3"
          >
            {{ item.price }} ₽ 
          </button>
          </div>

          
          <div class="flex md:hidden mt-6 h-9" v-if="inCartMethod(item)">
      <button class="bg-yellow-300 py-2 px-2.5 rounded-l-lg" @click="changeQuantity(item, 'decrease')"><img src="../assets/icons/trash.svg" /></button><button class="bg-yellow-300 text-easyText font-ceraroundLight py-2 px-0 text-xs w-16">{{ inCartQuantity(item) }} шт</button><button @click="changeQuantity(item)" class="bg-yellow-300 py-2 px-2.5 text-xl rounded-r-lg font-ceraroundLight"><img src="../assets/icons/plus.svg" /></button>
      </div>



          </div>
    </div>
        <p class="text-md font-ceraroundThin text-lg text-gray-400 mb-4 hidden md:block leading-6">{{ item.introtext }}</p>
     

    <div class="flex flex-wrap mt-auto pt-3 text-xs">
      
          <div class="md:flex flex-nowrap justify-between hidden w-full">
            <div class="text-easyGreen font-ceraroundMedium text-text3xl5 flex flex-wrap justify-center content-center"><p>{{ item.price }} ₽</p></div>

            <div class="flex flex-wrap" v-if="!inCartMethod(item)">
              <button @click="addToCart(item)" class="bg-easyGreen text-white font-ceraroundMedium py-4 px-4 rounded-lg w-28 text-sm">В корзину</button>
          </div>

          <div class="flex flex-wrap" v-if="inCartMethod(item)">
      <button class="bg-yellow-300 py-3 pl-3.5 rounded-l-lg" @click="changeQuantity(item, 'decrease')"><img src="../assets/icons/trash.svg" /></button><button class="bg-yellow-300 text-easyText font-ceraroundLight py-3 px-5 text-sm">{{ inCartQuantity(item) }} шт</button><button @click="changeQuantity(item)" class="bg-yellow-300 py-3 pr-3.5 rounded-r-lg"><img src="../assets/icons/plus.svg" /></button>
      </div>
          </div>
          
    </div>
  </div>
    <transition name="fade">
       <ModalItem v-if="modalVisible" :data="modalData" :isOpen="isMobile()" @close="modalVisible = false" />
       </transition>
      
</div>


</template>

<script>
import ModalItem from '@/components/ModalItem.vue';
import { computed } from "vue";
import { useStore } from "vuex";

export default {
   props: {
    itemData: Array,
  },
  setup(props) {
    const store = useStore();
    let cart = computed(function () {
            return store.state.cart
        });
        
         function changeQuantity(item, action = 'add'){

            if(action == 'add'){
                item.quantity = item.quantity + 1;

                store.commit("updateCartItem", item);
            }else{
                if(item.quantity > 1){
                    item.quantity = item.quantity - 1;
                    store.commit("updateCartItem", item);
                }else{
                    store.commit("removeCartItem", item)
                }
            }
        }

        function addToCart(item){
             store.commit("addCartItem", item);
        }

  
        return {
            cart,
            changeQuantity,
            addToCart
        }
  },
   components: {
    ModalItem,
  },
  name: "CatalogItem",
  data() {
    return {
      modalVisible: false,
      modalData: null,
    }
  },
  methods: {
    openModal(data) {
      this.modalData = data;
      this.modalVisible = true;
    },
    inCartMethod(item) {
        let inCart = false;
          this.cart.forEach((i) => {
                if(i.id == item.id){
                    inCart = true;
                }
            });
            return inCart;
        },
        inCartQuantity(item) {
              this.cart.forEach((i) => {
                if(i.id == item.id){
                    item.quantity = i.quantity;
                }
            });
            return item.quantity;
        },

    closeModal() {
      this.modalVisible = false;
    },
    isMobile() {
    if (screen.width <= 760) {
      return true;
    } else {
      return false;
    }
  },    
  }
 }
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>