<template>
  <div class="container mx-auto">
    <div class="flex flex-row">
      <div class="basis-1/4 flex flex-col justify-center items-center p-2.5">
        <img class="w-4/12 h-8" src="../assets/bottom_menu/house.svg" />
        <h4 class="text-[10px] mt-1.5 font-ceraroundRegular text-gray-400">
          Меню
        </h4>
      </div>
      <div class="basis-1/4 flex flex-col justify-center items-center p-2.5">
        <img class="w-4/12 h-8" src="../assets/bottom_menu/percent.svg" />
        <h4 class="text-[10px] mt-1.5 font-ceraroundRegular text-gray-400">
          Акции
        </h4>
      </div>
      <div class="basis-1/4 flex flex-col justify-center items-center p-2.5">
        <img class="w-4/12 h-8" src="../assets/bottom_menu/person.svg" />
        <h4 class="text-[10px] mt-1.5 font-ceraroundRegular text-gray-400">
          Кабинет
        </h4>
      </div>
      <div class="basis-1/4 flex flex-col justify-center items-center p-2.5">
        <img class="w-4/12 h-8" src="../assets/bottom_menu/bag.svg" />
        <h4 class="text-[10px] mt-1.5 font-ceraroundRegular text-gray-400">
          Корзина
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomMenu",
};
</script>
