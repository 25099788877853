import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./style.css";
import VueSmoothScroll from 'vue3-smooth-scroll'
import { registerScrollSpy } from 'vue3-scroll-spy';
import V3ScrollLock from 'v3-scroll-lock';

const app = createApp(App);
app.use(store).use(router).use(VueAxios, axios).use(VueSmoothScroll).use(V3ScrollLock, {});
registerScrollSpy(app);
app.mount("#app");